import {v4 as uuid} from "uuid"
import { created } from "../others_constants"

export default (title, handle, body_html, products, published = true, image = null) => ({
  title,
  handle,
  published,
  body_html,
  image,
  change_type: created,
  ...(products) && {
    products,
    collects: products.map(({ id }) => ({ product_id: id }))
  },
  id: uuid()
})
