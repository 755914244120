<template>
  <div class="ulsemo-stepper">
    <div
      v-for="step in steps"
      :key="step"
      :class="{
        'ulsemo-stepper__step': true,
        [`ulsemo-stepper__step-${step}--enabled`]:
          step <= current_step,
        [`ulsemo-stepper__step-${step}`]: true,
      }"
      @click="$router.push({ path: `/${GUIDE}/step_${step + 1}` })"
    >
      <span>{{ step }}</span>

      <div v-if="step === 4">
        <div class="ulsemo-stepper__step--triangle" />
        <div class="ulsemo-stepper__step--ellipse" />
      </div>
    </div>
  </div>
</template>

<script>
import { GUIDE } from "../../../../../data/page_types"

export default {
  props: {
    current_step: {
      type: Number,
      default: 0,
      required: true,
    },
    steps: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  data() {
    return {
      GUIDE,
    }
  },
};
</script>

<style lang="scss">
@use "../../../../../styles/_admin-constants" as *;
@use "../../../../../styles/_global-constants" as *;

.ulsemo-stepper {
  display: flex;
  flex-direction: row;
  gap: $default-size;
  margin: $double-default-size 0;

  &__step {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    font-size: $default-size;
    font-weight: 600;
    color: $pure-white;
    background-color: $material-grey--darker;
    cursor: pointer;

    &-1 {
      &--enabled {
        background-color: $logo-red;

        &:before {
          content: "";
          position: absolute;
          top: 50%;
          right: -#{$default-size};
          width: $default-size;
          height: 1px;
          transform: translateY(-50%);
          background-color: $pure-black;
          z-index: 1;
        }
      }
    }

    &-2 {
      &--enabled {
        background-color: $logo-black;

        &:before {
          content: "";
          position: absolute;
          top: 50%;
          right: -#{$default-size};
          width: $default-size;
          height: 1px;
          transform: translateY(-50%);
          background-color: $pure-black;
          z-index: 1;
        }
      }

      border-radius: 50%;
    }

    &-3 {
      &--enabled {
        background-color: $logo-blue;

        &:before {
          content: "";
          position: absolute;
          top: 50%;
          right: calc(-#{$default-size} - 7px);
          width: calc(#{$default-size} + 7px);
          height: 1px;
          transform: translateY(-50%);
          background-color: $pure-black;
          z-index: 1;
        }
      }
    }

    &-4 {
      overflow: hidden;
      background-color: transparent;

      &--enabled {
        .ulsemo-stepper__step--triangle {
          background-color: $logo-green;
        }
        .ulsemo-stepper__step--ellipse {
          background-color: $logo-green;
        }
      }

      span {
        position: absolute;
        left: 50%;
        z-index: 1;
      }
    }

    &--triangle {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-color: $material-grey--darker;
      clip-path: polygon(100% 0, 0 0, 100% 100%);
    }
    &--ellipse {
      position: absolute;
      top: -100%;
      right: -100%;
      width: 200%;
      height: 200%;
      background-color: $material-grey--darker;
      border-radius: 50%;
    }
  }
}
</style>
