import { created } from "../others_constants"

/**
 * @const {String} src - src of image
 * @const {String} attachment - Base64 converted image
 * @const {String} alt - alt text of image
 */
export default (src, attachment, alt = null) => ({
  src,
  alt,
  attachment,
  change_type: created,
  variant_ids: [],
})
