<template>
  <div
    class="admin-basic-button"
    :style="{
      color: color,
      borderColor: color,
    }"
    :class="{
      'admin-basic-button__bordered': bordered,
    }"
  >
    {{ text }}
  </div>  
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "",
      required: true
    },
    color: {
      type: String,
      default: "#000",
      required: false
    },
    bordered: {
      type: Boolean,
      default: false,
      required: false
    }
  }
}
</script>

<style lang="scss">
  @use "../../../../styles/_admin-constants" as *;
  @use "../../../../styles/_global-constants" as *;

  .admin-basic-button {
    color: $pure-black;
    padding: $half-default-size $default-size;
    text-align: center;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    border-bottom: 1px solid $pure-black;
    font-weight: bold;
    font-size: $font-size--medium;

    &__bordered {
      border: 1px solid $pure-black;
      border-radius: $border-radius;

      &:hover {
        background-color: $pure-black;
        color: $pure-white !important;
      }
    }

    &:hover {
      color: $black--light;
      border-color: transparent;
    }
  }
</style>
