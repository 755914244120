<template>
  <div
    class="admin-highlighted-button"
    :class="{
      'admin-highlighted-button--disabled': disabled,
    }"
  >
    <div>{{ text }}</div>
    <md-icon>navigate_next</md-icon>
  </div>  
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "",
      required: true
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    }
  }
}
</script>

<style lang="scss">
  @use "../../../../styles/_admin-constants" as *;
  @use "../../../../styles/_global-constants" as *;

  .admin-highlighted-button {
    position: relative;
    background-color: $logo-green;
    color: $pure-white;
    padding: 15px 30px;
    border-radius: $border-radius;
    text-align: center;
    font-weight: bold;
    font-size: $font-size--medium-large;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;

    &--disabled {
      background-color: $grey;
      cursor: not-allowed;

      &:hover {
        background-color: $grey !important;

        .md-icon {
          opacity: 0 !important;
        }
        div {
          transform: none !important;
        }
      }
    }

    div {
      transition: 200ms ease-in-out;
    }

    &:hover {
      background-color: $logo-green--darker;

      .md-icon {
        transition: 200ms ease-in-out;
        opacity: 1;
        left: calc(100% - 15px);
      }
      div {
        transform: translateX(-10px);
      }
    }

    .md-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      color: $pure-white !important;
      width: 30px;
      min-width: 30px;
      height: 30px;
      font-size: 30px !important;
    }
  }
</style>
